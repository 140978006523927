import type { FC } from "react";
import { Stack } from "@mui/material";

import { EmailRegisterForm } from "./EmailRegisterForm";
import { SocialLoginButton } from "./SocialLoginButton";
import type { FormPreset } from "@rimo/frontend/pages/About";
import { AuthButtonGroup, AuthButtonGroupBorder } from "./AuthButton";

export const InvitationForm: FC<FormPreset> = (props) => {
  return (
    <Stack rowGap={3.5}>
      <AuthButtonGroup>
        <SocialLoginButton provider="google" row isFirst />
        <AuthButtonGroupBorder />
        <SocialLoginButton provider="microsoft" row />
        <AuthButtonGroupBorder />
        <SocialLoginButton provider="facebook" row isLast />
      </AuthButtonGroup>
      <EmailRegisterForm {...props} />
    </Stack>
  );
};
