import React from "react";
import styled from "styled-components";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { isMobile, isIOS } from "react-device-detect";
import { useTheme } from "@mui/material";

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  height?: number | string;
  disableSwipeToOpen?: boolean;
  children?: React.ReactNode;
  keepMounted?: boolean | undefined;
}

// https://material-ui.com/components/drawers/#swipeable-temporary-drawer
// > This component comes with a 2 kB gzipped payload overhead.
// > Some low-end mobile devices won't be able to follow the fingers at 60 FPS.
// > You can use the disableBackdropTransition property to help.
export const Modal: React.FC<ModalProps> = ({
  children,
  open,
  height,
  disableSwipeToOpen = true,
  onClose,
  keepMounted = false,
}) => {
  const theme = useTheme();
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {
        console.log("opened");
      }}
      keepMounted={keepMounted}
      disableBackdropTransition={isMobile && !isIOS}
      disableDiscovery={isIOS}
      disableSwipeToOpen={disableSwipeToOpen}
      PaperProps={{
        style: {
          borderRadius: "18px 18px 0 0",
          minHeight: height || "auto",
          backgroundColor: theme.palette.background.experimental.layeredDefault,
          backgroundImage: "none",
        },
      }}
    >
      <Header>
        <Bar />
      </Header>
      <Body>{children}</Body>
    </SwipeableDrawer>
  );
};

const Body = styled.div`
  padding-bottom: 20px;
`;

const Header = styled.div`
  padding: 10px 0 29px 0;
`;

const Bar = styled.div`
  width: 32px;
  height: 5px;
  border-radius: 12px;
  border: 0px;
  margin: 0 auto;
  background: ${(p) => p.theme.vars.palette.text.light};
`;
