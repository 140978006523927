import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import React, { useRef, type JSX } from "react";
import styled from "styled-components";

import { color } from "../../styles/color";
import IconButton from "@mui/material/IconButton";

interface ButtonProps {
  width: number;
  height: number;
  radius: string;
}
interface ActionTextboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  text?: string;
  icon: JSX.Element;
  name?: string;
  error?: boolean;
  readOnly?: boolean;
  inputType?: string;
  placeholder?: string;
  autoComplete?: string;
  isDisabledAction?: boolean;
  buttonColor?: string;
  buttonProps?: ButtonProps;
  buttonType?: "submit" | "reset" | "button";
  buttonLabel?: string;
  onAction?: () => void;
  onChangeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ActionTextbox: React.FC<ActionTextboxProps> = ({
  text,
  icon,
  onAction,
  onBlur,
  name = "",
  error = false,
  readOnly = false,
  inputType = "text",
  placeholder = "",
  autoComplete = "",
  isDisabledAction = false,
  buttonColor = color.primary,
  buttonType = "button",
  buttonLabel,
  buttonProps = {
    width: 56,
    height: 56,
    radius: "50",
  },
  onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
  },
}) => {
  const { customT } = useClientTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Container>
      <Input
        name={name}
        type={inputType}
        readOnly={readOnly}
        $error={error}
        placeholder={placeholder}
        autoComplete={autoComplete}
        ref={inputRef}
        defaultValue={text}
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.select();
          }
        }}
        onChange={onChangeInput}
        onBlur={onBlur}
        onKeyUp={(e) => {
          if (buttonType === "submit" && e.key === "Enter") {
            onAction && onAction();
          }
        }}
      />

      <IconWrapper
        {...buttonProps}
        aria-haspopup="true"
        disabled={isDisabledAction}
        onClick={onAction}
        background={buttonColor}
        type={buttonType ?? "button"}
        aria-label={buttonLabel}
      >
        {customT(icon)}
      </IconWrapper>
    </Container>
  );
};

type ActionTextboxPropsWithRef = ActionTextboxProps & { inputProps?: React.HTMLAttributes<HTMLInputElement> };

export const ActionTextBoxRef = React.forwardRef<HTMLInputElement, ActionTextboxPropsWithRef>(
  function ActionTextBoxRef(props, ref) {
    const { customT } = useClientTranslation();
    const {
      text,
      icon,
      onAction,
      name = "",
      error = false,
      readOnly = false,
      inputType = "text",
      placeholder = "",
      autoComplete = "",
      isDisabledAction = false,
      buttonColor = color.primary,
      buttonType = "button",
      buttonLabel,
      buttonProps = {
        width: 56,
        height: 56,
        radius: "50",
      },
      onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
      },
      onBlur = (e: React.FocusEvent) => {
        e.preventDefault();
      },
      inputProps,
    } = props;

    return (
      <Container>
        <Input
          name={name}
          type={inputType}
          $error={error}
          readOnly={readOnly}
          placeholder={placeholder}
          autoComplete={autoComplete}
          ref={ref}
          defaultValue={text}
          onChange={onChangeInput}
          // autocompleteのフォーカスが外れるだけで、中身が空になり、リストが消失してしまう
          onBlur={onBlur}
          {...inputProps}
        />

        <IconWrapper
          {...buttonProps}
          aria-haspopup="true"
          disabled={isDisabledAction}
          onClick={onAction}
          background={buttonColor}
          type={buttonType}
          aria-label={buttonLabel}
        >
          {customT(icon)}
        </IconWrapper>
      </Container>
    );
  }
);

const Container = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
`;

// todo(kuri): ここをここをタイプによって変更できるようにする
const IconWrapper = styled(IconButton)<ButtonProps & { background: string }>`
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  border-radius: ${(p) => p.radius};
  margin-left: -27px;
  box-shadow:
    0px 8px 16px rgba(0, 0, 0, 0.1),
    0px 0px 0px rgba(0, 0, 0, 0.02);
  background-color: ${(p) => p.background};
  border: solid 1px transparent;

  &:hover {
    background: ${(p) => p.background};
  }
  &:disabled {
    background: ${(p) => p.theme.vars.palette.background.light};
    color: ${(p) => p.theme.vars.palette.text.light};
    border-color: ${(p) => p.theme.vars.palette.divider};
  }
`;

// -webkit-user-select: text; で user-select: all; をSafari上で無効化する
// cf. https://developer.mozilla.org/ja/docs/Web/CSS/user-select
export const Input = styled.input<{ $error: boolean }>`
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  height: 36px;
  padding: 5px 40px 5px 16px;
  background-color: ${(p) => p.theme.vars.palette.background.experimental.layeredLight};
  border: solid ${(p) => (p.$error ? "2px" : "1px")};
  border-color: ${(p) => (p.$error ? p.theme.vars.palette.error.main : p.theme.vars.palette.divider)};
  color: ${(p) => p.theme.vars.palette.text.secondary};
  border-radius: 18px;
  user-select: all;
  -webkit-user-select: text;
  cursor: all;
  outline: none;

  &::placeholder {
    color: ${(p) => p.theme.vars.palette.text.disabled};
  }
`;
