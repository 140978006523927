import { type ChangeEventHandler, type FocusEventHandler, type ReactNode, forwardRef } from "react";

import styled from "styled-components";
import { BodySmall } from "@rimo/ui-old";

/**
 * 単純なラジオボタンのみとして利用、レンダラー側で labelタグを定義し、クリック時の制御を容易にする
 */
export type RadioProps = Partial<{
  checked: boolean;
  id: string;
  name: string;
  value: string;
  disabled: boolean;
  variant: "border" | "check";
  onBlur: FocusEventHandler<HTMLInputElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
}>;

export const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ variant = "border", value, ...rest }, forwardedRef) => {
    if (variant === "border") {
      return <BorderRoundRadio ref={forwardedRef} type="radio" id={value} value={value} {...rest} />;
    }
    if (variant === "check") {
      return <CheckRadio ref={forwardedRef} type="radio" id={value} value={value} {...rest} />;
    }
    return null;
  }
);

/**
 * フォームの入力として汎用的に利用するラジオボタン
 */

export type FormRadioProps = RadioProps &
  Required<{
    label: ReactNode;
  }>;

export const FormRadio = forwardRef<HTMLInputElement, FormRadioProps>(({ label, value, ...rest }, forwardedRef) => {
  return (
    <FormItem htmlFor={value}>
      <Radio ref={forwardedRef} id={value} value={value} {...rest} />
      {typeof label === "string" ? <BodySmall as="span">{label}</BodySmall> : label}
    </FormItem>
  );
});

const FormItem = styled.label`
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  input[type="radio"] {
    margin: 6px;
    margin-right: 12px;
  }
`;

const BorderRoundRadio = styled.input`
  cursor: pointer;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  border: solid 1px ${(p) => p.theme.vars.palette.text.light};
  margin: 0; // デフォルトで挿入されるmarginを削除
  flex-shrink: 0;
  &:checked {
    border: solid 4px ${(p) => p.theme.vars.palette.primary.main};
  }
`;

const CheckRadio = styled.input`
  cursor: pointer;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  border: solid 1px ${(p) => p.theme.vars.palette.text.light};
  margin: 0; // デフォルトで挿入されるmarginを削除
  flex-shrink: 0;
  &:checked {
    border: none;
    background-image: url("/assets/radio_checked.svg");
    background-size: contain;
  }
`;
