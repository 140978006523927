"use client";

import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";

import Image from "next/legacy/image";
import type { ButtonHTMLAttributes, FC, MouseEvent} from "react";
import { useCallback } from "react";

import { AuthButton, AuthRowButton } from "./AuthButton";
import type { SNS } from "@rimo/frontend/services/firebase/auth/signIn";
import { useRouter } from "next/navigation";
import {
  copyQueryStringForAdvertisement,
  QueryStringSamlProviderIdKey,
} from "@rimo/frontend/services/firebase/auth/signUp";

import { objectToSearchParamsString } from "@rimo/frontend/hooks/useNavigation";

export const SocialLoginButton: FC<{
  provider: SNS;
  samlProviderId?: string | undefined;
  row?: boolean | undefined;
  isFirst?: boolean | undefined;
  isLast?: boolean | undefined;
  redirectUrl?: string | undefined;
}> = ({
  provider,
  samlProviderId = undefined,
  row = false,
  isFirst = false,
  isLast = false,
  redirectUrl = "/login/redirect",
}) => {
  const { customT } = useClientTranslation();

  const router = useRouter();

  const text =
    provider === "google"
      ? "Google"
      : provider === "microsoft"
        ? "Microsoft"
        : provider === "facebook"
          ? "Facebook"
          : provider === "saml"
            ? "SSO"
            : "";

  const src =
    provider === "google"
      ? "/assets/g_logo.svg"
      : provider === "microsoft"
        ? "/assets/m_logo.svg"
        : provider === "facebook"
          ? "/assets/fb_logo.svg"
          : provider === "saml"
            ? "/assets/sso_logo.svg"
            : "";

  const handleClick = useCallback(
    async (e: MouseEvent) => {
      e.preventDefault();

      const data = copyQueryStringForAdvertisement();
      const query = Object.assign(
        {},
        data,
        samlProviderId
          ? {
              provider,
              [QueryStringSamlProviderIdKey]: samlProviderId,
            }
          : {
              provider,
            }
      );

      const queryString = objectToSearchParamsString(query);
      router.push(`${redirectUrl}${queryString}`);
    },
    [samlProviderId, provider, router, redirectUrl]
  );

  return row ? (
    <AuthRowButton
      icon={<Image src={src} width={20} height={20} alt={`${provider} logo`} />}
      onClick={handleClick}
      isFirst={isFirst}
      isLast={isLast}
    >
      {customT(text)}
    </AuthRowButton>
  ) : (
    <AuthButton icon={<Image src={src} width={24} height={24} alt={`${provider} logo`} />} onClick={handleClick}>
      {customT(text)}
    </AuthButton>
  );
};

interface SocialButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  provider: SNS;
}

export const SocialButton: FC<SocialButtonProps> = ({ provider, ...rest }) => {
  const { customT } = useClientTranslation();
  const text =
    provider === "google"
      ? "Google"
      : provider === "microsoft"
        ? "Microsoft"
        : provider === "facebook"
          ? "Facebook"
          : provider === "saml"
            ? "SSO"
            : "";

  const src =
    provider === "google"
      ? "/assets/g_logo.svg"
      : provider === "microsoft"
        ? "/assets/m_logo.svg"
        : provider === "facebook"
          ? "/assets/fb_logo.svg"
          : provider === "saml"
            ? "/assets/sso_logo.svg"
            : "";
  return (
    <AuthButton {...rest} icon={<Image src={src} width={24} height={24} alt={`${provider} logo`} />}>
      {customT(text)}
    </AuthButton>
  );
};
