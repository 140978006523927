import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { Dialog } from "@mui/material";
import type { ModalProps} from "../layouts/Modal";
import { Modal } from "../layouts/Modal";
import { Margin, PopupPrimaryButton } from "../ui";

const Popup: React.FC<ModalProps> = (props) => {
  return isMobile ? (
    <Modal {...props}>{props.children}</Modal>
  ) : (
    <Dialog
      {...props}
      maxWidth="xl"
      aria-labelledby="common-dialog-title"
      aria-describedby="common-dialog-description"
      PaperProps={{ elevation: 0 }}
    >
      {props.children}
    </Dialog>
  );
};

export const SpamMailPopup: React.FC<ModalProps> = (props) => {
  const { customT } = useClientTranslation();

  return (
    <Popup {...props}>
      <Container>
        <Margin top={isMobile ? 4 : 40}>
          <Title>{customT("メールが届かない場合")}</Title>
        </Margin>
        <Margin top={12}>
          <Description>
            {customT(
              "メールが届かない場合には迷惑メールフォルダなどもご確認下さい。間違えてスパム判定をしてしまっていた場合には、 support@rimo.app についてスパム解除をお願いします。"
            )}
          </Description>
        </Margin>
        <Margin top={24}>
          <Right>
            <PopupPrimaryButton onClick={props.onClose}>OK</PopupPrimaryButton>
          </Right>
        </Margin>
      </Container>
    </Popup>
  );
};

const Container = styled.div`
  min-height: 240px;
  width: ${isMobile ? "100%" : "640px"};
  padding: 0 ${isMobile ? "16px" : "40px"};
  box-sizing: border-box;
`;
const Title = styled.h2`
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: ${(p) => p.theme.vars.palette.text.primary};
`;
const Description = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${(p) => p.theme.vars.palette.text.primary};
`;
const Right = styled.div`
  display: flex;
  justify-content: flex-end;
`;
