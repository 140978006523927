"use client";

import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import { isLanguageCode } from "@rimo/i18n/settings";

import { useRouter, useSearchParams } from "next/navigation";
import Link from "next/link";
import type { FC, PropsWithChildren } from "react";
import { useMemo, useContext, useEffect } from "react";
import styled from "styled-components";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";
import queryString from "query-string";

import { Header } from "./genericLP/Header";
import Nl2br from "@rimo/frontend/components/utils/Nl2br";
import { VoiceLayout } from "../components/layouts/VoiceLayout";
import { LoginForm } from "../components/auth/LoginForm";
import { InvitationForm } from "../components/auth/InvitationForm";
import { UserContext } from "../contexts/UserContext";
import { usePageView } from "../hooks/useGtag";
import { useTypedSearchParams } from "@rimo/frontend/hooks/useNavigation";
import { EmailLoginForm } from "../components/auth/EmailLoginForm";
import { EmailRegisterForm } from "../components/auth/EmailRegisterForm";
import { AuthLayout, LoginSubtitle, RegisterSubtitle } from "./Login";
import type { AuthProvider } from "../types/auth";
import { PasswordForm } from "../components/auth/PasswordForm";
import { Button } from "@rimo/ui-old";
import { SessionStorage } from "../services/storage/SessionStorage";
import { getAuth } from "firebase/auth";
import { onPageReload } from "../services/firebase/auth/event";
import { useInjectUserIntoReactApp } from "../hooks/useInjectUserIntoReactApp";
import { Constant } from "../constants/constant";

const title = ["音声で、", "記録しよう。"];
const description = isMobile
  ? ["Rimoを使えば打合せ中にメモを取ること", "に集中力を奪われることがなくなります。"]
  : [
      "AIで音声を即データ化。音声データと一緒に文字データを即時で作成。",
      "データはシェア・編集可能。Rimoを使えば打合せ中にメモを取ること",
      "に集中力を奪われることがなくなります。",
    ];

// 現在のpathのlocaleに基づいて、authURLを作成する
export const createAuthURL = (baseUrl: string, params: { [key: string]: string }): string => {
  const pathname = window.location.pathname;
  const search = window.location.search;
  const parts = queryString.parse(search.slice(1));
  const combo = Object.entries({ ...parts, ...params })
    .map(([key, value]) => (key ? `${key}=${value}` : null))
    .filter((v) => !!v);

  const locale = isLanguageCode(pathname.split("/")[1]) ? `/${pathname.split("/")[1]}` : "";
  return `${locale}${baseUrl}?${combo.join("&")}`;
};
export interface FormPreset {
  new?: string;
  last?: string;
  first?: string;
  email?: string;
  role?: "email_register" | "email_login" | "password";
  provider?: AuthProvider;
  saml_provider_id?: string;
}

export const About: FC = () => {
  const { customT } = useClientTranslation();

  const router = useRouter();
  const searchParams = useSearchParams();
  const inject = useInjectUserIntoReactApp();
  const query = useTypedSearchParams<FormPreset>();
  const {
    userState: { user, isLoaded },
  } = useContext(UserContext);

  // 招待用のフォームを表示するかどうか判定する
  // query-stringにemail情報を入力しているかどうか
  const isInvited = useMemo(() => query.email && query.email !== "", [query.email]);

  usePageView({ pageTitle: title.join("\n") });

  // ソーシャルログイン・メールアドレスでのユーザーログイン時の招待自動承認に利用する
  useEffect(() => {
    const v = searchParams?.get("confirm");
    const orgId = typeof v === "string" ? v : undefined;
    if (orgId) SessionStorage.setItem("invitationConfirming", orgId);
  }, [searchParams]);

  // ユーザーがログイン済みの状況で "/about" へアクセスした場合
  useEffect(() => {
    const clearId = setTimeout(() => {
      const { currentUser } = getAuth();
      if (user) {
        router.push(Constant.Url.Internal.TopPage);
      } else if (!user && currentUser) {
        // inject()で UserContext(user)を設定、当該useEffect()の router.push(topPage)の動作を促す
        onPageReload(currentUser).then((r) => inject(r));
      }
    }, 3000);
    return () => {
      clearTimeout(clearId);
    };
  }, [user, router, inject]);

  return !isLoaded ? null : user ? (
    <>
      <Box sx={{ position: "absolute", top: 12, right: 12 }}>
        <Button variant="text" onClick={() => router.push(Constant.Url.Internal.Logout)}>
          {customT("ログアウト")}
        </Button>
      </Box>
      <ProgressUserSession>
        <Typography color="text.primary" fontSize={24}>
          {customT("Rimo Voice ログイン処理中")}
        </Typography>
        <Link href={`/`} passHref legacyBehavior>
          <Button size="large">{customT("ノート一覧画面へ移動する")}</Button>
        </Link>
      </ProgressUserSession>
    </>
  ) : query.role === "email_register" ? (
    <AuthLayout
      variant="voice"
      title={customT("メールアドレスで登録")}
      subTitle={<RegisterSubtitle url={createAuthURL("/about", { role: "email_login" })} />}
    >
      <EmailRegisterForm {...query} />
    </AuthLayout>
  ) : query.role === "email_login" ? (
    <AuthLayout
      variant="voice"
      title={customT("メールアドレスでログイン")}
      subTitle={<LoginSubtitle url={createAuthURL("/about", { role: "email_register" })} />}
    >
      <EmailLoginForm {...query} hrefResetPassword={createAuthURL("/about", { role: "password" })} />
    </AuthLayout>
  ) : query.role === "password" ? (
    <AuthLayout
      title={`パスワード${isMobile ? "\n" : ""}リセット`}
      spaceTops={isMobile ? [24, 89, 12] : [120, 56, 24]}
      variant="voice"
    >
      <PasswordForm />
    </AuthLayout>
  ) : (
    <VoiceLayout>
      <Header target="writing" />
      <Main>
        <HeaderStyle>
          <Title>
            <Nl2br text={title} />
          </Title>
          <Description>
            <Nl2br text={description} />
          </Description>
        </HeaderStyle>
        <FormContainer $margin={isInvited ? 32 : undefined}>
          {isInvited && !query.provider ? <InvitationForm {...query} /> : <LoginForm {...query} />}
        </FormContainer>
      </Main>
    </VoiceLayout>
  );
};

export const ProgressUserSession = ({ children }: PropsWithChildren) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      rowGap={2}
      sx={{
        bgcolor: "background.experimental.layeredDefault",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <CircularProgress size={64} thickness={1} />
      {children}
    </Stack>
  );
};

const HeaderStyle = styled.div`
  width: ${isMobile ? "100%" : "660px"};
  ${!isMobile &&
  `
    flex-grow: 1;
    max-width: 800px;
  `};
`;

const Title = styled.div`
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: ${(p) => p.theme.vars.palette.text.primary};

  ${isMobile
    ? `
    font-size: 56px;
    line-height: 72px;
  `
    : `
    font-size: 120px;
    line-height: 157px;
  `}
`;

const FormContainer = styled.div<{ $margin?: number }>`
  ${!isMobile
    ? `
    width: 343px;
    margin-top: 26px;
  `
    : (p) => `margin-top: ${p.$margin ?? 64}px;`};
`;

const Main = styled.div`
  margin-top: ${isMobile ? "92" : "142"}px;
  display: ${isMobile ? "block" : "flex"};
  color: ${(p) => p.theme.vars.palette.text.primary};

  ${!isMobile &&
  `
    padding: 0 64px;
    justify-content: center;
  `};
`;

const Description = styled.div`
  margin-top: ${isMobile ? "14" : "40"}px;
  font-size: ${isMobile ? "16" : "18"}px;
  font-weight: 600;
  line-height: 28px;
  color: ${(p) => p.theme.vars.palette.text.primary};
`;

export default About;
