import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import React, { useCallback, useMemo, useContext } from "react";

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { IconButton, CircularProgress } from "@mui/material";
import { isMobile } from "react-device-detect";
import type { UseFormRegisterReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import { NotificationDispatchContext } from "@rimo/frontend/contexts/NotificationContext";
import { useInjectUserIntoReactApp } from "@rimo/frontend/hooks/useInjectUserIntoReactApp";
import type { FormPreset } from "@rimo/frontend/pages/About";
import { signUpWithEmailAndPassword } from "@rimo/frontend/services/firebase/auth/signUp";
import { color } from "@rimo/ui-old";

import { ActionTextBoxRef, Input } from "../../components/layouts/ActionTextbox";
import { Margin } from "../ui";
import { Policy } from "./LoginForm";

type EmailRegisterProps = FormPreset &
  Partial<{
    onComplete: Parameters<typeof signUpWithEmailAndPassword>[3];
  }>;

interface FormData {
  lastName: string;
  firstName: string;
  email: string;
  password: string;
}

// propsは親ページから取得
export const EmailRegisterForm: React.FC<EmailRegisterProps> = (props) => {
  const { customT } = useClientTranslation();

  const dispatch = useContext(NotificationDispatchContext);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    mode: "onBlur",
    defaultValues: {
      lastName: props?.last ?? "",
      firstName: props?.first ?? "",
      email: props?.email ?? "",
      password: "",
    },
  });

  const [lastNameRegister, firstNameRegister, emailRegister, passwordRegister] = useMemo(
    () => [
      register("lastName", {
        required: "お名前(姓)は必須です",
        maxLength: { value: 10, message: "10文字以下で入力してください" },
      }),
      register("firstName", {
        required: "お名前(名)は必須です",
        maxLength: { value: 10, message: "10文字以下で入力してください" },
      }),
      register("email", {
        required: "メールアドレスは必須です",
        pattern: {
          value:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: "不正なメールアドレスです",
        },
      }),
      register("password", {
        required: "パスワードは必須です",
        minLength: {
          value: 6,
          message: "6文字以上入力してください",
        },
      }),
    ],

    [register]
  );

  const injectUser = useInjectUserIntoReactApp();
  const onSubmit = useCallback(
    async (data: FormData) => {
      try {
        const result = await signUpWithEmailAndPassword(
          data.email,
          data.password,
          {
            lastName: data.lastName.trim(),
            firstName: data.firstName.trim(),
          },
          props.onComplete
        );
        injectUser(result);
      } catch (e) {
        dispatch({
          type: "ERROR",
          payload: { message: e instanceof Error ? e.message : "不明なエラーが発生しました" },
        });
      }
    },
    [dispatch, injectUser, props.onComplete]
  );

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Label $error={!!errors.lastName || !!errors.firstName}>{customT("お名前")}</Label>
        <HorizontalContainer>
          <div>
            <AuthTextbox
              errorMessage={errors.lastName?.message}
              placeholder={customT("姓")}
              register={lastNameRegister}
              registerId="lastName"
              required
            />
          </div>
          <Margin left={8}>
            <AuthTextbox
              errorMessage={errors.firstName?.message}
              placeholder={customT("名")}
              register={firstNameRegister}
              registerId="firstName"
              required
            />
          </Margin>
        </HorizontalContainer>
      </div>
      <Margin top={12}>
        <AuthTextbox
          autoComplete="email"
          errorMessage={errors.email?.message}
          inputType="email"
          label={customT("メールアドレス")}
          placeholder={customT("メールアドレスを入力")}
          register={emailRegister}
          registerId="email"
          required
        />
      </Margin>
      <Margin top={12}>
        {isMobile ? (
          <>
            <AuthTextbox
              autoComplete="new-password"
              errorMessage={errors.password?.message}
              inputType="password"
              label={customT("パスワード")}
              placeholder={customT("パスワードを入力")}
              register={passwordRegister}
              registerId="password"
              required
            />

            <MobileSubmitButton />
          </>
        ) : (
          <>
            <Label $error={!!errors.password}>{customT("パスワード")}</Label>
            <Margin top={6}>
              <ActionTextBoxRef
                autoComplete="new-password"
                buttonColor={color.primary}
                buttonType="submit"
                buttonLabel={customT("メールアドレスで登録")}
                error={!!errors.password}
                icon={
                  isSubmitting ? (
                    <CircularProgress size={18} sx={{ color: "text.experimental.onPrimary" }} />
                  ) : (
                    <SubmitIcon />
                  )
                }
                inputType="password"
                isDisabledAction={isSubmitting}
                name={passwordRegister.name}
                onBlur={passwordRegister.onBlur}
                onChangeInput={passwordRegister.onChange}
                placeholder={customT("パスワードを入力")}
                ref={passwordRegister.ref}
                required
              />

              {errors.password && <ErrorMessage>{customT(errors.password.message)}</ErrorMessage>}
            </Margin>
          </>
        )}
      </Margin>
      <Margin top={15}>
        <Policy />
      </Margin>
    </form>
  );
};

type AuthTextboxProps = {
  registerId: string;
  register: UseFormRegisterReturn;
  label?: string | undefined;
  inputType?: string | undefined;
  placeholder?: string | undefined;
  errorMessage?: string | undefined;
  required?: boolean | undefined;
  autoComplete?: string | undefined;
  disabled?: boolean | undefined;
  error?: boolean | undefined;
};
export const AuthTextbox: React.FC<AuthTextboxProps> = ({
  label,
  registerId,
  register,
  inputType,
  placeholder,
  errorMessage,
  required,
  autoComplete,
  disabled,
}) => {
  const { customT } = useClientTranslation();

  const isError = useMemo(() => !!errorMessage, [errorMessage]);
  return (
    <>
      {label && <Label $error={isError}>{customT(label)}</Label>}
      <Margin top={6}>
        <Input
          autoComplete={autoComplete}
          disabled={disabled}
          $error={isError}
          id={registerId}
          name={customT(register.name)}
          onBlur={register.onBlur}
          onChange={register.onChange}
          placeholder={customT(placeholder) ?? ""}
          ref={register.ref}
          required={required ?? false}
          type={customT(inputType) ?? customT("text")}
        />

        {isError && <ErrorMessage>{customT(errorMessage)}</ErrorMessage>}
        {/* <Error>{errorMessage}&nbsp;</Error> */}
      </Margin>
    </>
  );
};

export const MobileSubmitButton: React.FC = () => {
  return (
    <StickContainer>
      <IconSubmit type="submit">
        <SubmitIcon height={20} width={20} />
      </IconSubmit>
    </StickContainer>
  );
};

const Label = styled.span<{ $error?: boolean }>`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${(p) => (p.$error ? p.theme.vars.palette.error.main : p.theme.vars.palette.text.secondary)};
`;

const ErrorMessage = styled.p`
  margin-top: 2px;
  color: ${(p) => p.theme.vars.palette.error.main};
`;
export { ErrorMessage as EmailRegisterError, Label as EmailRegisterLabel };

const SubmitIcon = styled(ArrowForwardRoundedIcon)<{ width?: number; height?: number }>`
  color: ${(p) => p.theme.vars.palette.text.experimental.onPrimary};
  width: ${(p) => p.width ?? 24}px;
  height: ${(p) => p.width ?? 24}px;
`;

const HorizontalContainer = styled.div`
  display: flex;
`;

const StickContainer = styled.div`
  position: fixed;
  right: 16px;
  bottom: 24px;
`;
const IconSubmit = styled(IconButton)`
  background-color: ${color.primary};
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  &:hover {
    background-color: ${color.primary};
  }
`;
