import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import React, { useCallback, useMemo, useContext, useState } from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { useForm } from "react-hook-form";
import { ActionTextBoxRef } from "../layouts/ActionTextbox";
import { Margin } from "../ui";
import { EmailRegisterLabel, EmailRegisterError, AuthTextbox, MobileSubmitButton } from "./EmailRegisterForm";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { color } from "@rimo/ui-old";
import { NotificationDispatchContext } from "@rimo/frontend/contexts/NotificationContext";
import Nl2br from "@rimo/frontend/components/utils/Nl2br";
import { SpamMailPopup } from "./SpamMailPopup";
import { sendResetPasswordEmail } from "@rimo/frontend/services/firebase/auth/mail";

type FormData = { email: string };

const description = ["登録済みのメールアドレスを入力してください", "送信するメールからパスワードを再設定できます"];

export const PasswordForm: React.FC = () => {
  const { customT } = useClientTranslation();
  const [isWarning, setIsWarning] = useState(false);
  const notificationDispatch = useContext(NotificationDispatchContext);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    mode: "onBlur",
    defaultValues: {
      email: "",
    },
  });

  const [emailRegister] = useMemo(
    () => [
      register("email", {
        required: "メールアドレスは必須です",
        pattern: {
          value:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: "不正なメールアドレスです",
        },
      }),
    ],

    [register]
  );

  const onSubmit = useCallback(
    async (data: FormData) => {
      if (isSubmitting) return false;
      try {
        await sendResetPasswordEmail(data.email);
        reset();

        const payload = { message: "パスワードリセットメールを送信しました" };
        notificationDispatch({ type: "INFO", payload });
      } catch (e) {
        const message = e instanceof Error ? e.message : "不明なエラーが発生しました";
        notificationDispatch({ type: "ERROR", payload: { message } });
      }
    },
    [isSubmitting, notificationDispatch, reset]
  );

  return (
    <Container>
      <Text>
        <Nl2br text={description} />
      </Text>
      <Margin top={40}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Margin top={6}>
            {isMobile ? (
              <>
                <AuthTextbox
                  errorMessage={errors.email?.message}
                  label={customT("メールアドレス")}
                  inputType="email"
                  registerId="email"
                  register={emailRegister}
                  required
                  placeholder={customT("メールアドレスを入力")}
                />

                <MobileSubmitButton />
              </>
            ) : (
              <>
                <EmailRegisterLabel $error={!!errors.email}>{customT("メールアドレス")}</EmailRegisterLabel>
                <ActionTextBoxRef
                  error={!!errors.email}
                  inputType="email"
                  placeholder={customT("メールアドレスを入力")}
                  required
                  icon={<SubmitIcon />}
                  buttonColor={color.primary}
                  buttonType="submit"
                  buttonLabel={customT("パスワードリセット")}
                  name={emailRegister.name}
                  onChangeInput={emailRegister.onChange}
                  onBlur={emailRegister.onBlur}
                  ref={emailRegister.ref}
                />

                {errors.email && <EmailRegisterError>{customT(errors.email.message)}</EmailRegisterError>}
              </>
            )}
          </Margin>
        </form>
        <Margin top={isMobile ? 6 : 8}>
          <WarnLink onClick={() => setIsWarning(true)}>{customT("メールが届かない場合")}</WarnLink>
        </Margin>
      </Margin>
      <SpamMailPopup open={isWarning} onClose={() => setIsWarning(false)} />
    </Container>
  );
};

const Container = styled.div``;
const Text = styled.p`
  font-size: 15px;
  line-height: 24px;
  color: ${(p) => p.theme.vars.palette.text.primary};
  text-align: center;
`;
const SubmitIcon = styled(ArrowForwardRoundedIcon)<{ width?: number; height?: number }>`
  color: ${(p) => p.theme.vars.palette.text.experimental.onPrimary};
  width: ${(p) => p.width ?? 24}px;
  height: ${(p) => p.width ?? 24}px;
`;
const WarnLink = styled.p`
  font-size: 12px;
  line-height: 24px;
  text-decoration-line: underline;
  color: ${(p) => p.theme.vars.palette.text.secondary};
  cursor: pointer;
`;
