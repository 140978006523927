import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { useThemeMode } from "@rimo/ui-old";

export const VoiceLayout: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  return <Container>{props.children}</Container>;
};

export const VoiceLogo: React.FC = () => {
  const themeMode = useThemeMode();
  const logoSrc = themeMode === "light" ? "rimo_logo.svg" : "logo_white.svg";
  return <img src={`/assets/${logoSrc}`} alt="logo" />;
};

export const VoiceToolbar: React.FC = () => (
  <div className={`flex w-full pt-6 ${isMobile ? "pl-0" : "pl-3"} box-border`}>
    <VoiceLogo />
  </div>
);

const Container = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-y: hidden;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/assets/about_voice/waves.svg");
  background-position:
    top ${isMobile ? 90 : 149}px left 0,
    top;
  background-color: ${(p) => p.theme.vars.palette.background.experimental.layeredDefault};
`;
