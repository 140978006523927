"use client";

import type { FC, PropsWithChildren, ReactNode} from "react";
import { useMemo, useEffect, useContext, Fragment } from "react";

import { useRouter, useSearchParams } from "next/navigation";
import Link from "next/link";

import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { IconButton } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";

import { useTypedSearchParams } from "../hooks/useNavigation";
import type { FormPreset } from "./About";
import { NotificationDispatchContext } from "../contexts/NotificationContext";
import { UserContext } from "../contexts/UserContext";
import { LoginForm } from "../components/auth/LoginForm";
import { VoiceLogo, VoiceLayout } from "../components/layouts/VoiceLayout";
import { Margin } from "../components/ui";
import { usePageView } from "../hooks/useGtag";
import Nl2br from "@rimo/frontend/components/utils/Nl2br";
import { SessionStorage } from "../services/storage/SessionStorage";
import { AIEditorBackground } from "./AIEditorLP/Background";
import { Header as AIEditorHeader } from "./AIEditorLP/Header";

interface LoginQuery {
  from: string;
  confirm: string;
  kind: "" | "edit";
}

export const Login: FC = () => {
  const query = useTypedSearchParams<LoginQuery & FormPreset>();
  const searchParams = useSearchParams();
  const {
    userState: { user, isLoaded },
  } = useContext(UserContext);
  const notificationDispatch = useContext(NotificationDispatchContext);

  const title = useMemo(() => (query.kind === "edit" ? "編集" : "記録"), [query.kind]);

  usePageView();

  useEffect(() => {
    if (isLoaded) {
      const from = searchParams?.get("from");
      // ログイン後の遷移先パスが指定されている場合のみ
      if (!user && typeof from === "string" && from !== "") {
        notificationDispatch({ type: "ERROR", payload: { message: "先の処理をするためにログインをしてください" } });
        SessionStorage.setItem("redirectTo", from);
      }
    }
  }, [isLoaded, notificationDispatch, query.from, user, searchParams]);

  return !isLoaded || user ? null : (
    <AuthLayout variant="voice" title={`ログインして${isMobile ? "\n" : ""}${title}する`}>
      <LoginForm {...query} />
    </AuthLayout>
  );
};

export const AuthLayout: FC<
  PropsWithChildren<{
    title: string;
    variant: "voice" | "ai-editor";
    subTitle?: ReactNode | undefined;
    spaceTops?: [number, number, number] | undefined;
  }>
> = ({ title, children, subTitle, spaceTops, variant }) => {
  const { customT } = useClientTranslation();
  const router = useRouter();
  const [spaceTopLogo, spaceTopTitle, spaceTopChildren] = (spaceTops ?? isMobile) ? [24, 89, 83] : [120, 56, 40];

  // AIEditorBackendはchildrenを取らないため、コンポーネントに充て方が異なる
  const Component = variant === "voice" ? VoiceLayout : Fragment;

  return (
    <Component>
      {variant === "ai-editor" && <AIEditorBackground />}
      {variant === "ai-editor" ? (
        <AIEditorHeader />
      ) : (
        <Margin top={spaceTopLogo}>
          <Center>
            <VoiceLogo />
          </Center>
        </Margin>
      )}

      <Margin top={spaceTopTitle}>
        <Title>
          <Nl2br text={title} />
        </Title>
      </Margin>
      {subTitle && <div>{customT(subTitle)}</div>}
      <Margin top={spaceTopChildren}>
        <Center>
          <FormContainer>{children}</FormContainer>
        </Center>
      </Margin>
      {variant === "ai-editor" ? null : (
        <StickContainer>
          <Icon
            onClick={(e) => {
              e.preventDefault();
              router.back();
            }}
          >
            <ArrowBackRoundedIcon />
          </Icon>
        </StickContainer>
      )}
    </Component>
  );
};

export const RegisterSubtitle: FC<{ url: string }> = ({ url }) => {
  const { customT } = useClientTranslation();

  return (
    <Margin top={24}>
      <Center>
        <SubTitle>{customT("新規登録して早速Rimoを使ってみよう。")}</SubTitle>
        &nbsp;
        <Link href={url} passHref replace legacyBehavior>
          <SubTitleLink>{customT("すでに登録済みの方はこちら")}</SubTitleLink>
        </Link>
      </Center>
    </Margin>
  );
};

export const LoginSubtitle: FC<{ url: string }> = ({ url }) => {
  const { customT } = useClientTranslation();

  return (
    <Margin top={24}>
      <Center>
        <SubTitle>
          {customT("すでに登録済みの方は下記から再ログインできます。")}
          &nbsp;
          <Link href={url} passHref replace legacyBehavior>
            <SubTitleLink>{customT("新規登録はこちら")}</SubTitleLink>
          </Link>
        </SubTitle>
      </Center>
    </Margin>
  );
};

const Center = styled.div`
  display: flex;
  justify-content: center;

  ${isMobile &&
  `
    flex-direction: column;
    align-items: center;
  `}
`;

const FormContainer = styled.div`
  width: ${isMobile ? "100%" : "343px"};
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: ${isMobile ? 56 : 36}px;
  text-align: center;
  color: ${(p) => p.theme.vars.palette.text.primary};
`;

const SubTitle = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: ${(p) => p.theme.vars.palette.text.primary};
  text-align: center;
`;

const SubTitleLink = styled.a`
  font-size: 15px;
  line-height: 24px;
  color: ${(p) => p.theme.vars.palette.text.secondary};
  text-decoration: underline;
`;

const StickContainer = styled.div`
  position: fixed;
  top: ${isMobile ? 16 : 24}px;
  left: ${isMobile ? 16 : 24}px;
`;

const Icon = styled(IconButton)`
  background-color: ${(p) => p.theme.vars.palette.background.surface};
  padding: ${isMobile ? 8 : 12}px;
`;

export default Login;
