import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import React from "react";

interface NL2BRProps {
  text: string | string[];
}

// 翻訳されるべき文字列を渡すときには配列にして渡す
const Nl2br: React.FC<NL2BRProps> = ({ text }) => {
  const { customT } = useClientTranslation();
  const textList = Array.isArray(text) ? text : text.split("\n");

  return (
    <React.Fragment>
      {textList.map((item, key) => (
        <React.Fragment key={key}>
          {customT(item)}
          <br />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default Nl2br;
