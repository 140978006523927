import Button from "@mui/material/Button";
import styled from "styled-components";

import { color } from "../../styles/color";

export const PrimaryButton = styled(Button)<{
  height?: number;
  width?: number;
  fontSize?: number;
  whitespace?: string;
  disabled?: boolean;
}>`
  background: ${(props) => (props.disabled ? "rgba(0, 0, 0, 0.03)" : color.primary)};
  border: none;
  border-radius: ${(props) => (props.height ? props.height / 2 : 24)}px;
  box-shadow:
    0px 8px 16px rgba(0, 0, 0, 0.1),
    0px 0px 0px rgba(0, 0, 0, 0.02);
  color: #fff;
  padding: 12px 33px;
  line-height: ${(props) => `${props.height ?? 24}px`};
  font-weight: 600;
  font-size: ${(props) => props.fontSize}px;
  height: ${(props) => props.height}px;
  width: ${(props) => (props.width ? `${props.width}px` : "auto")};
  white-space: ${(props) => props.whitespace};
  &:hover {
    background: ${color.primary};
    opacity: 0.95;
  }
`;
PrimaryButton.defaultProps = {
  height: 48,
  fontSize: 16,
  whitespace: "normal",
};

export const PopupPrimaryButton = styled(PrimaryButton)`
  box-shadow: none;
`;
