// cf. https://chaika.hatenablog.com/entry/2021/11/03/083000
const authProviders = ["google", "microsoft", "facebook", "email", "saml"] as const;
export type AuthProvider = typeof authProviders[number];

export const isAuthProvider = (provider?: string): provider is AuthProvider => {
  return authProviders.some((value) => value === provider);
};

export const validateAuthProvider = (provider?: string): AuthProvider | undefined => {
  if (isAuthProvider(provider)) {
    return provider;
  }
  return undefined;
};

export const convertFirebaseProviderToAuthProvider = (firebaseProvider: string): AuthProvider | undefined => {
  switch (firebaseProvider) {
    case "password":
      return "email";
    case "google.com":
      return "google";
    case "microsoft.com":
      return "microsoft";
    case "facebook.com":
      return "facebook";
    default:
      return;
  }
};
