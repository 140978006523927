import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import { useLocaleContext } from "@rimo/frontend/contexts/LocaleProvider";
import Link from "next/link";
import React, { useMemo } from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { SocialLoginButton } from "./SocialLoginButton";
import { Margin } from "../ui";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { AuthButton } from "./AuthButton";
import type { FormPreset } from "@rimo/frontend/pages/About";
import { createAuthURL } from "@rimo/frontend/pages/About";
import { validateAuthProvider } from "@rimo/frontend/types/auth";
import { useSearchParamsString } from "@rimo/frontend/hooks/useNavigation";
import { Stack } from "../ui/Flexbox";
import { Constant } from "@rimo/frontend/constants/constant";
import { isProd } from "@rimo/frontend/utils/system";
import { Trans } from "react-i18next/TransWithoutContext";
import { useSearchParams } from "next/navigation";

type Props = Pick<FormPreset, "new" | "provider" | "saml_provider_id">;

interface EmailLoginFormProps {
  btnRole: string;
  btnText: string;
  linkRole?: string;
  linkText?: string;
  email?: string;
}

export const AIEditorLoginForm = ({
  provider,
  samlProviderId,
  organizationEndpoint = false,
  hrefEmailLogin,
  hrefEmailRegister,
}: {
  provider?: string | undefined;
  samlProviderId?: string | undefined;
  organizationEndpoint?: boolean | undefined;
  hrefEmailLogin?: string | undefined;
  hrefEmailRegister?: string | undefined;
}) => {
  const { customT } = useClientTranslation();
  const { locale } = useLocaleContext();
  const prov = validateAuthProvider(provider);

  if (!isProd) {
    console.debug("AIEditorLoginForm", { prov, provider });
  }

  // if (prov) {
  //   return (
  //     <Container>
  //       {!isMobile && <SignInText>{customT("下記アカウントでログイン")}</SignInText>}
  //       {(prov === "google" || prov === "microsoft" || prov === "facebook") && (
  //         <Margin bottom={16}>
  //           <SocialLoginButton provider={prov} redirectUrl={Constant.Url.AIEditor.RedirectOAuth} />
  //         </Margin>
  //       )}
  //       {prov === "saml" && samlProviderId && (
  //         <Margin bottom={16}>
  //           <SocialLoginButton
  //             provider="saml"
  //             samlProviderId={samlProviderId}
  //             redirectUrl={Constant.Url.AIEditor.RedirectOAuth}
  //           />
  //         </Margin>
  //       )}
  //       {prov === "email" && hrefEmailLogin && (
  //         // <EmailLoginForm props={email} />
  //         // createAuthURL(btnProps)
  //         <EmailButton url={hrefEmailLogin}>{customT("メールアドレス")}</EmailButton>
  //       )}
  //       {prov === "email" && hrefEmailRegister && (
  //         // createAuthURL({ role: "email_register" }
  //         <Link href={hrefEmailRegister}>
  //           <EmailLoginLinkContainer>{customT("メールアドレスで登録")}</EmailLoginLinkContainer>
  //         </Link>
  //       )}
  //       <Margin top={4}>
  //         <Policy />
  //       </Margin>
  //     </Container>
  //   );
  // }

  const searchParams = useSearchParams();
  const redirectTo = `${Constant.Url.AIEditor.RedirectOAuth}?${searchParams?.toString()}`;

  return (
    <Container>
      {organizationEndpoint === true && (
        <OrganizationLinkContainer>
          <Link href={`/${locale}/login/organizations`}>{customT("法人の方はこちら")}</Link>
        </OrganizationLinkContainer>
      )}
      <Stack $rowGap="16">
        <SocialLoginButton provider="google" redirectUrl={redirectTo} />
        <SocialLoginButton provider="microsoft" redirectUrl={redirectTo} />
        <SocialLoginButton provider="facebook" redirectUrl={redirectTo} />
        {Boolean(samlProviderId) && (
          <SocialLoginButton provider="saml" samlProviderId={samlProviderId} redirectUrl={redirectTo} />
        )}
        {hrefEmailLogin && <EmailButton url={hrefEmailLogin}>{customT("メールアドレス")}</EmailButton>}
        {hrefEmailRegister && (
          <Link href={hrefEmailRegister}>
            <EmailLoginLinkContainer>{customT("メールアドレスで登録")}</EmailLoginLinkContainer>
          </Link>
        )}
      </Stack>
      <Margin top={4}>
        <Policy />
      </Margin>
    </Container>
  );
};

export const LoginForm: React.FC<Props> = ({ new: newUser, provider, saml_provider_id }) => {
  const { customT } = useClientTranslation();
  const { locale } = useLocaleContext();
  const isRegister = useMemo(() => newUser === "true", [newUser]);
  const prov = validateAuthProvider(provider);

  const email = isRegister
    ? {
        btnRole: "email_register",
        btnText: "メールアドレス",
        linkRole: "email_login",
        linkText: "メールアドレスで登録済みの方",
      }
    : {
        btnRole: "email_login",
        btnText: "メールアドレス",
        linkRole: "email_register",
        linkText: "メールアドレスで登録",
      };

  return (
    <Container>
      {prov && !isMobile && <SignInText>{customT("下記アカウントでログイン")}</SignInText>}

      {!prov && (
        <OrganizationLinkContainer>
          <Link href={`/${locale}/login/organizations`}>{customT("法人の方はこちら")}</Link>
        </OrganizationLinkContainer>
      )}

      {(!prov || prov === "google") && (
        <Margin bottom={16}>
          <SocialLoginButton provider="google" />
        </Margin>
      )}

      {(!prov || prov === "microsoft") && (
        <Margin bottom={16}>
          <SocialLoginButton provider="microsoft" />
        </Margin>
      )}

      {(!prov || prov === "facebook") && (
        <Margin bottom={16}>
          <SocialLoginButton provider="facebook" />
        </Margin>
      )}

      {prov === "saml" && saml_provider_id && (
        <Margin bottom={16}>
          <SocialLoginButton provider="saml" samlProviderId={saml_provider_id} />
        </Margin>
      )}

      {(!prov || prov === "email") && <EmailLoginForm props={email} />}
      <Margin top={4}>
        <Policy />
      </Margin>
    </Container>
  );
};

export const Policy: React.FC = () => {
  const { locale } = useLocaleContext();
  const searchParamsString = useSearchParamsString();
  return (
    <Footer>
      <Policies>
        <Trans
          i18nKey="<Link1 >利用規約</Link1>と<Link2>プライバシーポリシー</Link2>に同意して開始"
          components={{
            Link1: <FooterLink href={`/${locale}/policies/terms${searchParamsString}`} passHref target="_blank" />,
            Link2: <FooterLink href={`/${locale}/policies/privacy${searchParamsString}`} passHref target="_blank" />,
          }}
        />
      </Policies>
    </Footer>
  );
};

// children(props)に値を渡せば通常のボタン・渡さなければshrinkされたアイコンだけのボタンになる
const EmailButton: React.FC<{ url: string; children?: string }> = ({ url, children }) => (
  <Link href={url} passHref legacyBehavior>
    <AuthButton icon={<EmailIcon />}>{children ? children : undefined}</AuthButton>
  </Link>
);

const EmailLoginLinkContainer = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.vars.palette.text.secondary};
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  outline: none;
  border-radius: 50px;
  transition: all 200ms;

  &:hover {
    background-color: ${(p) => p.theme.vars.palette.background.surface};
  }
`;

export const EmailLoginForm: React.FC<{ props: EmailLoginFormProps }> = ({ props }) => {
  const { customT } = useClientTranslation();
  const btnProps = useMemo(() => {
    const p: { [key: string]: string } = { role: props.btnRole };
    if (props.email) p.email = props.email;
    return p;
  }, [props]);

  return (
    <div>
      <Margin bottom={16}>
        <EmailButton url={createAuthURL("/about", btnProps)}>{customT(props.btnText)}</EmailButton>
      </Margin>
      {props.linkRole && props.linkText && (
        <Margin bottom={16}>
          <Link href={createAuthURL("/about", { role: props.linkRole })} passHref legacyBehavior>
            <EmailLoginLinkContainer>{customT(props.linkText)}</EmailLoginLinkContainer>
          </Link>
        </Margin>
      )}
    </div>
  );
};

const Container = styled.div``;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  ${isMobile && "margin-bottom: 90px;"}
`;

const FooterLink = styled(Link)`
  font-size: 12px;
  line-height: 24px;
  color: ${(p) => p.theme.vars.palette.text.secondary};
  text-decoration-line: underline;
`;

const Policies = styled.div`
  display: inline;
  font-size: 12px;
  line-height: 24px;
  color: ${(p) => p.theme.vars.palette.text.secondary};
`;

const EmailIcon = styled(EmailRoundedIcon)`
  color: ${(p) => p.theme.vars.palette.text.secondary};
`;

const SignInText = styled.h2`
  color: ${(p) => p.theme.vars.palette.text.primary};
  font-size: 24px;
  margin-bottom: 30px;
`;

const OrganizationLinkContainer = styled.div`
  text-align: right;
  color: ${(p) => p.theme.vars.palette.text.secondary};
  text-decoration: underline;
  margin-bottom: 12px;
`;
