import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import LoginAPI from "@rimo/frontend/api/login";
import Sentry from "@rimo/frontend/utils/sentry";
import { createFirebaseAuthenticateErrorMessage } from "./error";

export async function sendResetPasswordEmail(email: string): Promise<void> {
  try {
    const resp = await LoginAPI.validateEmail(email).catch((err: Error) => {
      throw new Error("入力されたメールアドレスの認証チェック中にエラーが発生しました: " + err.message);
    });
    if (resp.notExists) {
      throw new Error("このメールアドレスはパスワードリセットができません");
    }
    if (!resp.passwordLoginAcceptable) {
      throw new Error("このメールアドレスはパスワードリセットができません");
    }
  } catch (e) {
    Sentry.captureException(e, { extra: { email, backend_url: "/email/validate", phase: "reset_password" } });
    throw e;
  }

  try {
    await sendPasswordResetEmail(getAuth(), email);
  } catch (err) {
    const defaultError = "パスワードリセットメールの送信処理中に不明なエラーが発生しました";
    const [error, isFirebaseError] = createFirebaseAuthenticateErrorMessage(err, defaultError);
    if (!isFirebaseError) Sentry.captureException(err);
    console.warn(`[Firebase Authentication] sendPasswordResetEmail(email="${email}")`, err);
    throw error;
  }
}
