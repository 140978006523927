import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import React from "react";
import styled from "styled-components";
import { Margin } from "../ui";

type AuthButtonProps = {
  icon?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children?: string;
};

export const AuthButton: React.FC<AuthButtonProps> = (props) => {
  const { customT } = useClientTranslation();
  return (
    <BaseAuthButton $shadow $topLeft={50} $topRight={50} $bottomRight={50} $bottomLeft={50} onClick={props.onClick}>
      {!!props.icon && <StartIcon $absolute>{customT(props.icon)}</StartIcon>}
      <Margin right={14}>
        <Text>{props.children}</Text>
      </Margin>
    </BaseAuthButton>
  );
};

export const AuthRowButton: React.FC<AuthButtonProps & { isFirst?: boolean; isLast?: boolean }> = (props) => {
  const { customT } = useClientTranslation();
  return (
    <BaseAuthButton
      $horizon
      $topLeft={props.isFirst ? 50 : 0}
      $bottomLeft={props.isFirst ? 50 : 0}
      $topRight={props.isLast ? 50 : 0}
      $bottomRight={props.isLast ? 50 : 0}
      onClick={props.onClick}
    >
      <StartIcon>{customT(props.icon)}</StartIcon>
      <Margin left={8}>
        <Text $fontSize={12}>{props.children}</Text>
      </Margin>
    </BaseAuthButton>
  );
};

interface BorderRadius {
  $topLeft?: number | undefined;
  $topRight?: number | undefined;
  $bottomRight?: number | undefined;
  $bottomLeft?: number | undefined;
}

// horizon: ボタン内部を横並びにする
// shadow: ボタン自体に影をつける
const BaseAuthButton = styled.button<BorderRadius & { $horizon?: boolean; $shadow?: boolean }>`
  position: relative;
  padding: 14px 0 14px 16px;
  height: 52px;
  width: 100%;
  border: none;
  cursor: pointer;
  background-color: ${(p) => p.theme.vars.palette.background.experimental.layeredLight};
  transition: all 200ms;
  &:hover {
    background-image: linear-gradient(
      ${(p) => p.theme.vars.palette.background.surface},
      ${(p) => p.theme.vars.palette.background.surface}
    );
  }
  border-top-left-radius: ${(p) => p.$topLeft ?? 0}px;
  border-top-right-radius: ${(p) => p.$topRight ?? 0}px;
  border-bottom-right-radius: ${(p) => p.$bottomRight ?? 0}px;
  border-bottom-left-radius: ${(p) => p.$bottomLeft ?? 0}px;
  ${(p) =>
    p.$horizon &&
    `
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `}
  ${(p) =>
    p.$shadow &&
    `
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.02);
  `}
`;

// absolute: 絶対値を使ったIconの配置を行う(BaseAuthButtonのpaddingに則している)
const StartIcon = styled.div<{ $absolute?: boolean }>`
  ${(p) =>
    p.$absolute &&
    `
    position: absolute;
    top: 14px;
    left: 16px;
  `}
`;

const Text = styled.p<{ $fontSize?: number }>`
  font-size: ${(p) => p.$fontSize ?? 16}px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${(p) => p.theme.vars.palette.text.secondary};
`;

// AuthButtonGroupでのみ利用
export const AuthButtonGroup = styled.div<BorderRadius>`
  display: flex;
  align-items: center;
  height: 52px;
  width: 100%;
  border: none;
  background-color: ${(p) => p.theme.vars.palette.background.experimental.layeredDefault};
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 0px 0px rgba(0, 0, 0, 0.02);
  border-top-left-radius: ${(p) => p.$topLeft ?? 0}px;
  border-top-right-radius: ${(p) => p.$topRight ?? 0}px;
  border-bottom-right-radius: ${(p) => p.$bottomRight ?? 0}px;
  border-bottom-left-radius: ${(p) => p.$bottomLeft ?? 0}px;
`;
export const AuthButtonGroupBorder = styled.div`
  min-width: 1px;
  max-width: 1px;
  height: 42px;
  border: none;
`;
