import type { FC } from "react";

import { forwardRef, memo } from "react";

import { useTheme } from "styled-components";

import MuiCheckIcon from "@mui/icons-material/Check";
import type { ListItemTextProps } from "@mui/material/ListItemText";
import ListItemText from "@mui/material/ListItemText";
import type { MenuItemProps } from "@mui/material/MenuItem";
import MenuItem from "@mui/material/MenuItem";
import type { SelectProps as MuiSelectProps } from "@mui/material/Select";
import MuiSelect from "@mui/material/Select";
import type { SvgIconProps } from "@mui/material/SvgIcon";

import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";

type OverrideSelectProps = {
  transparent: boolean;
  width: number | string;
  borderRadius: "small" | "medium" | "round";
  paperBorderRadius: "small" | "medium";
  fontSize: "small" | "medium";
  variant: MuiSelectProps["variant"];
};
type SelectProps = Omit<MuiSelectProps, "variant"> & Partial<OverrideSelectProps>;

const SelectRFC = forwardRef<unknown, SelectProps>(
  ({ transparent, width, paperBorderRadius = "medium", borderRadius: br = "round", fontSize, ...rest }, ref) => {
    const { customT } = useClientTranslation();
    const theme = useTheme();
    const backgroundColor = transparent ? "transparent" : theme.palette.background.surface;
    const borderRadius = br === "round" ? "46px !important" : br === "small" ? 1 : 2;
    return (
      <MuiSelect
        inputRef={ref}
        displayEmpty
        {...rest}
        MenuProps={{
          // prevent ClickAwayListener.onClickAway
          // ref: https://github.com/mui/material-ui/issues/13216
          disablePortal: true,
          variant: "menu",
          MenuListProps: {
            disablePadding: true,
            autoFocusItem: false,
            sx: {
              maxHeight: 300,
            },
          },
          PaperProps: {
            sx: {
              borderRadius: paperBorderRadius === "small" ? 1 : 4,
              backgroundColor: "background.experimental.layeredLight",
              backgroundImage: "none",
            },
          },
          ...rest.MenuProps,
        }}
        disableUnderline
        renderValue={(values) => {
          const { placeholder, renderValue } = rest;
          const resp = renderValue ? renderValue(values) : null;
          return resp ? resp : placeholder ? <em>{customT(placeholder)}</em> : null;
        }}
        sx={{
          width: width ?? "auto",
          backgroundColor,
          borderRadius, // input(borderRadius)
          "& .MuiSelect-select": {
            paddingY: 0,
            paddingLeft: 2,
            borderRadius,
            fontSize: fontSize === "small" ? 12 : fontSize === "medium" ? 14 : 14,
            lineHeight: "36px",
            color: "text.secondary",
          },
          "& .MuiSelect-select:focus": {
            backgroundColor,
          },
          ...rest.sx,
        }}
        variant="standard"
      />
    );
  }
);

export const Select = memo(SelectRFC);

export const SelectItem: FC<
  MenuItemProps & { variant?: "error" | "standard" } & { textProps?: ListItemTextProps } & {
    iconProps?: SvgIconProps;
  } & { children: string | React.ReactNode }
> = ({ textProps, variant, iconProps, selected, children, ...props }) => {
  return (
    <MenuItem
      {...props}
      sx={{
        backgroundColor: "background.experimental.layeredLight",
        transition: "all 200ms",
        "&:hover": {
          backgroundColor: "background.surface",
        },
        ...props.sx,
      }}
    >
      <ListItemText
        {...textProps}
        sx={{
          fontSize: "1rem",
          lineHeight: 1.2,
          paddingX: 1,
          paddingY: 0.5,
          ...textProps?.sx,
          color: variant === "error" ? "#F77B74" : "text.secondary",
          span: {
            display: "flex",
            alignItems: "center",
          },
        }}
      >
        {children}
      </ListItemText>
      {selected && (
        <MuiCheckIcon
          {...iconProps}
          sx={{
            bgcolor: "primary.main",
            borderRadius: "50%",
            color: "#ffffff",
            width: 14,
            height: 14,
            ...iconProps?.sx,
          }}
        />
      )}
    </MenuItem>
  );
};
