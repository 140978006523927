"use client";
import styled from "styled-components";

export const Padding = styled.div<{ top?: number; right?: number; bottom?: number; left?: number }>`
  padding-top: ${(props) => props.top}px;
  padding-right: ${(props) => props.right}px;
  padding-bottom: ${(props) => props.bottom}px;
  padding-left: ${(props) => props.left}px;
`;
Padding.defaultProps = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

export const Margin = styled.div<{ top?: number; right?: number; bottom?: number; left?: number }>`
  margin-top: ${(props) => props.top}px;
  margin-right: ${(props) => props.right}px;
  margin-bottom: ${(props) => props.bottom}px;
  margin-left: ${(props) => props.left}px;
`;
Margin.defaultProps = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};
